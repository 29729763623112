/*@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap');*/

.component_logo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: var(--logo-width)
}
img {
  max-width: 25%;
  height: auto;
  max-height: 130px;
  object-fit: cover;
}

.header {
  font-family: var(--main-font);
  font-size: 40px;
  font-weight: bold;
  color: #027353;

}
